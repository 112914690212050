﻿/**
 *  @ngdoc controller
 *  @name Notification Controller
 *  @description Notification controller
 * <h2>Dependencies</h2>
 * "$scope", "$state", "$rootScope", "$http", "appSettings", "$localStorage"
 */
(function () {
    "use:strict";
    angular.module("myApp").controller("NotificationCtrl", ["$scope", "$rootScope", "$state", NotificationCtrl]);
   
    function NotificationCtrl($scope, $rootScope, $state) {
        var nt = this;
        nt.diffDays = $rootScope.diffDays;

        nt.close = function () {
            $rootScope.showNotification = false;
            localStorage.setItem("showNotification", false);
        }
        nt.changePassword = function () {
            $state.go('changePassword');
        }

    }
})();



